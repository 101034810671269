<template>
  <tr>
    <td class="justify-center">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <div v-on="on">
            <v-btn color="primary" outline small :to="{ name: 'network-list', params: { page: 'host' }, query: { producer_model_id: props.item.id } }">
              <v-icon>router</v-icon>
              <span>Listar Equipamentos</span>
            </v-btn>
          </div>
        </template>
        <span>Acessar equipamentos deste fabricante</span>
      </v-tooltip>
      <HostProducerModelDialog
        v-if="producerModelDialog"
        v-model="producerModelDialog"
        :model="props.item"
        :host="{ id: null }"
      ></HostProducerModelDialog>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <div v-on="on">
            <v-btn color="green" outline small @click="producerModelDialog = true">
              <v-icon>router</v-icon>
              <span>Detalhes do modelo</span>
            </v-btn>
          </div>
        </template>
        <span>Acessar detalhes deste modelo</span>
      </v-tooltip>
    </td>
    <td class="justify-left">
      <span>
        {{ props.item.id }}
      </span>
    </td>
    <td class="justify-left">
      <strong style="font-size: 20px;">
        {{ props.item.name }}
      </strong>
    </td>
    <td class="justify-left">
      <span v-if="props.item.image">
        <img :src="props.item.image" style="padding: 10px; max-width: 100px;" :alt="props.item.name"/>
      </span>
      <span v-else>- - -</span>
    </td>
  </tr>
</template>
<script>
  import HostProducerModelDialog from '@/modules/network/pages/Host/ProducerModel/Dialog'
  export default {
    name: 'ProducerModels',
    data: () => ({
      producerModelDialog: false,
      producerAndProducerModel: null
    }),
    props: {
      config: Object,
      props: Object
    },
    components: {
      HostProducerModelDialog
    }
  }
</script>
<style>
td {
  padding: 0 5px !important;
}
th {
  padding: 0 5px !important;
}
</style>